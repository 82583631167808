<template>
    <div class="noData">
        <Svgs color="#fff" classes="noDataSvg" name="iconNoDataicon"></Svgs>
        <div class="noDataTxt">暂无数据</div>
    </div>
</template>

<script>
    export default {
        name: "NoData"
    }
</script>

<style lang="scss">
    .noData {
        color: #5B6C76;
        font-size: .25rem;
        text-align: center !important;
        position: relative;
    }
    .noDataSvg{
        width: 2rem!important;
        height: 2rem!important;
        margin-top: .5rem;
    }
    .noDataTxt{
        margin-top: -.2rem;
        text-align: center !important;
    }
</style>